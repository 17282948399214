import * as React from "react";
import { Link, graphql } from "gatsby";
import * as dayjs from "dayjs";

// import Bio from "../components/bio";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { MdOutlineClass } from "react-icons/md";
import { INVALID_DATE } from "../helper/contants";
import { formatReadingTime } from "../helper/utils";

const PitfallIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`;
  const post = data.markdownRemark;
  const posts = data.allMarkdownRemark.nodes.sort((a, b) => {
    return dayjs(a.frontmatter.createTime > b.frontmatter.createTime ? -1 : 1);
  });
  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Lucas's blog" />
      <ol style={{ listStyle: `none` }}>
        {posts.map((post) => {
          const title = post.frontmatter.title || post.fields.slug;
          return (
            <li key={post.fields.slug}>
              <article
                className="post-list-item"
                itemScope
                itemType="http://schema.org/Article"
              >
                <header>
                  <h2>
                    <Link to={post.fields.slug} itemProp="url">
                      <span itemProp="headline">{title}</span>
                    </Link>
                  </h2>
                  <small>{post.frontmatter.date}</small>
                </header>
                <div className="post-info">
                  <span className="post-info-item">
                    发表于 {post.frontmatter.createTime}
                  </span>
                  {post.frontmatter.updateTime !== INVALID_DATE && (
                    <span className="post-info-item">
                      更新于 {post.frontmatter.updateTime}
                    </span>
                  )}
                  <span className="post-info-item">
                    <MdOutlineClass
                      style={{ marginRight: 4, verticalAlign: "middle" }}
                    />
                    <a>{post.frontmatter.type}</a>
                  </span>
                  <span style={{ marginLeft: 8 }}>{`${formatReadingTime(
                    post.timeToRead
                  )}`}</span>
                </div>
                <section>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: post.frontmatter.description || post.excerpt,
                    }}
                    itemProp="description"
                  />
                </section>
              </article>
            </li>
          );
        })}
      </ol>
    </Layout>
  );
};

export default PitfallIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { group: { eq: "pitfall" } } }
      sort: { fields: [frontmatter___createTime], order: DESC }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        timeToRead
        frontmatter {
          createTime(formatString: "YYYY/MM/DD")
          updateTime(formatString: "YYYY/MM/DD")
          title
          type
          tags
          description
        }
      }
    }
  }
`;
